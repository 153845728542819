import { Box, Button, ListItem } from '@mui/material';

import { SearchResultDto } from '../../models/shopping-list/SearchResultDto';

interface SearchResultProps {
    result: SearchResultDto;
    onClickFunction: any;
}

const SearchResult = (props: SearchResultProps): JSX.Element => {
    return (
        <ListItem divider={true}>
            <Button
                fullWidth
                style={{ justifyContent: 'space-between' }}
                onClick={() => props.onClickFunction(props.result)}
            >
                {props.result.image ? (
                    <img src={props.result.image} height="100%" width="50" />
                ) : (
                    <Box
                        sx={{ width: 50, height: 50, backgroundColor: 'gray' }}
                    />
                )}
                <Box>{props.result.name}</Box>
                <Box sx={{ width: 60 }}>{props.result.price}</Box>
            </Button>
        </ListItem>
    );
};

export default SearchResult;
