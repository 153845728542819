import { Select, MenuItem, SelectProps } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { selectLanguages } from '../../store/language/language-slice';
import { useAppSelector } from '../../store/hooks';
import { LanguageDto } from '../../models/language/LanguageDto';

type LanguageSelectProps = SelectProps;

const LanguageSelect = (
    props: LanguageSelectProps,
    ref: ForwardedRef<unknown>
): JSX.Element => {
    const languages: LanguageDto[] = useAppSelector(selectLanguages);

    return (
        <Select {...props} ref={ref} size="small">
            {languages.map((it) => (
                <MenuItem value={it.id} key={it.id}>
                    {it.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default forwardRef(LanguageSelect);
