import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ItemDto } from '../../models/shopping-list/ItemDto';
import { ShoppingListDto } from '../../models/shopping-list/ShoppingListDto';

interface TemplatesItemsTableProps {
    template?: ShoppingListDto;
    eventItems?: ItemDto[];
}

const TemplatesItemsTable = (props: TemplatesItemsTableProps): JSX.Element => {
    const { template, eventItems } = props;
    const { t } = useTranslation();

    return (
        <Paper>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {template?.event ? (
                            <>
                                <TableCell>{t('item.item')}</TableCell>
                                <TableCell align="right">
                                    {t('item.amount')}
                                </TableCell>
                                <TableCell align="right">
                                    {t('item.per-person')}
                                </TableCell>
                            </>
                        ) : (
                            <>
                                <TableCell>{t('item.item')}</TableCell>
                                <TableCell align="right">
                                    {t('item.amount')}
                                </TableCell>
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {template?.event ? (
                        <>
                            {eventItems?.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell align="right">
                                        {item.amountOrdered}
                                    </TableCell>
                                    <TableCell align="right">
                                        {item.perPerson}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <>
                            {template?.items.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell align="right">
                                        {item.amountOrdered}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    )}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default TemplatesItemsTable;
