import { Add, Apartment } from '@mui/icons-material';
import { Box, Button, List, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { ShoppingListDto } from '../../models/shopping-list/ShoppingListDto';
import styles from './ShoppingListTab.module.css';
import listStyles from '../shopping-list-item/ShoppingListItem.module.css';
import { formatDate } from '../../utility/date-helper';
import ShoppingListItem from '../shopping-list-item/ShoppingListItem';
import { useNavigate } from 'react-router-dom';
import { ItemDto } from '../../models/shopping-list/ItemDto';
import { ChangeEvent, useEffect, useState } from 'react';
import SortButton from '../sort-button/SortButton';
import {
    sortByItemName,
    sortByItemLikes,
    sortByPopularity,
    SortType,
    SortOptions,
    sortByStoreName,
} from '../../utility/sort-helper';
import Markdown from '../markdown/Markdown';
import { isAdmin, isEventOrganizer } from '../../utility/user-helper';
import { selectCurrentUser } from '../../store/user/user-slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import moment from 'moment';
import RemindersIconButton from '../reminders-icon-button/RemindersIconButton';
import CopyListMenu from '../copy-list-menu/CopyListMenu';
import { filterByStoreNames, getAllStores } from '../../utility/filter-helper';
import FilterMenu from '../filter-menu/FilterMenu';
import {
    setOrderedAmountAsync,
    setPeopleAmountAsync,
} from '../../store/shopping-list/shopping-list-slice';
import SortMenu from '../sort-menu/SortMenu';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

interface ShoppingListTabProps {
    list: ShoppingListDto;
    value: number;
}

const ShoppingListTab = (props: ShoppingListTabProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { value, list } = props;
    const user = useAppSelector(selectCurrentUser);

    const allStores: String[] = getAllStores(list.items);
    const [filterOptions, setFilterOptions] = useState<String[]>(
        getAllStores(list.items)
    );

    const [filteredItems, setFilteredItems] = useState<ItemDto[]>(
        filterByStoreNames(list.items, filterOptions)
    );

    const [sortOptions, setSortOptions] = useState<SortOptions>({
        sortType: SortType.Name,
        sortDescending: true,
    });
    const [sortedItems, setSortedItems] = useState<ItemDto[]>(
        sortByItemLikes(list.items, sortOptions.sortDescending)
    );
    const [peopleAmount, setPeopleAmount] = useState<number>(list.peopleAmount);

    const handleInputChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        event.target.focus();
        const value = Number(event.target.value);
        if (value >= 0) {
            setPeopleAmount(Number(value));
            if (!list.ordered) {
                const items = JSON.parse(JSON.stringify(sortedItems));
                setSortedItems(handleEventItems(value, items));
            }
        } else {
            setPeopleAmount(0);
        }
    };

    const handlePeopleAmountChange = async (event: any): Promise<void> => {
        const value = Number(event.target.value);
        if (value >= 0) {
            setPeopleAmount(Number(value));
            try {
                await dispatch(
                    setPeopleAmountAsync({
                        peopleAmount: value,
                        listId: list.id,
                    })
                ).unwrap();
            } catch {}

            if (!list.ordered) {
                for (const item of sortedItems) {
                    try {
                        await dispatch(
                            setOrderedAmountAsync({
                                data: {
                                    itemId: item.id,
                                    amountOrdered: item.amountOrdered,
                                },
                                listId: item.shoppingListId,
                            })
                        ).unwrap();
                    } catch {}
                }
            }
        } else {
            setPeopleAmount(0);
        }
    };

    const handleEventItems = (pAmount: number, items: ItemDto[]): ItemDto[] => {
        for (const item of items) {
            if (item.perPerson !== 0) {
                item.amountOrdered = Math.ceil(pAmount * item.perPerson);
            }
        }
        return items;
    };

    useEffect(() => {
        setFilteredItems(filterByStoreNames(list.items, filterOptions));
        // Force update of sort option to update list
        setSortOptions({
            sortType: sortOptions.sortType,
            sortDescending: sortOptions.sortDescending,
        });
    }, [filterOptions, list]);

    useEffect(() => {
        switch (sortOptions.sortType) {
            case SortType.Name:
                setSortedItems(
                    sortByItemName(filteredItems, sortOptions.sortDescending)
                );
                break;
            case SortType.Likes:
                setSortedItems(
                    sortByItemLikes(filteredItems, sortOptions.sortDescending)
                );
                break;
            case SortType.Store:
                setSortedItems(
                    sortByStoreName(filteredItems, sortOptions.sortDescending)
                );
                break;
            case SortType.Popularity:
                setSortedItems(
                    sortByPopularity(filteredItems, sortOptions.sortDescending)
                );
                break;
            default:
                setSortedItems(filteredItems);
                break;
        }
    }, [sortOptions, list]);

    const isDueDatePassed = moment(list.dueDate, true).isBefore(Date.now());

    function getListClassNames(): string {
        const classNames: string[] = [];
        classNames.push(listStyles.listGrid);
        classNames.push(listStyles.listHeader);
        list.ordered && classNames.push(listStyles.pastOrder);
        list.template && classNames.push(listStyles.template);
        list.template &&
            list.event &&
            classNames.push(listStyles.eventTemplate);
        (isAdmin(user) || isEventOrganizer(user)) &&
            classNames.push(listStyles.adminView);
        return classNames.join(' ');
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== list.id}
            id={`shopping-list-tabpanel-${list.id}`}
            className={styles.tabRoot}
        >
            {value === list.id && (
                <div>
                    <Grid2 container spacing={2} className="flex-center">
                        {list.template && !list.event && (
                            <Typography>{t('list.template')}</Typography>
                        )}
                        {list.template && list.event && (
                            <Typography>{t('list.template_event')}</Typography>
                        )}
                        {!list.template && list.event && (
                            <Typography sx={{ marginTop: '10px' }}>
                                {t('list.event')}
                            </Typography>
                        )}
                        <Grid2 xs={12} className="flex-center">
                            <Typography variant="h1">{list.name}</Typography>
                        </Grid2>
                        <Grid2
                            xs={12}
                            className="flex-center"
                            sx={{ marginTop: '-12px' }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ fontWeight: 'bold' }}
                                color="info.main"
                                className={styles.deliveryOfficeName}
                            >
                                {list.listDeliveryOffice.name}
                            </Typography>
                            <Apartment
                                fontSize="medium"
                                sx={{
                                    color: 'info.main',
                                    paddingBottom: '4px',
                                }}
                            />
                        </Grid2>
                        <Grid2 xs={12}>
                            <Markdown>{list.comment}</Markdown>
                        </Grid2>
                        <Grid2
                            md={4}
                            xs={12}
                            order={{ xs: 4, md: 1 }}
                            className="flex-center"
                        >
                            <Button
                                startIcon={<Add />}
                                variant="contained"
                                onClick={() => {
                                    list.template
                                        ? navigate(
                                              `/template/${list.id}/add-item`
                                          )
                                        : navigate(
                                              `/order/${list.id}/add-item`
                                          );
                                }}
                                disabled={isDueDatePassed || list.ordered}
                                fullWidth
                            >
                                {t('actions.add_new_item')}
                            </Button>
                        </Grid2>
                        {!list.template && (
                            <Grid2 md={6} xs={10} order={{ xs: 1, md: 2 }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color:
                                            isDueDatePassed && !list.ordered
                                                ? 'error.main'
                                                : 'inherit',
                                    }}
                                >
                                    {list.ordered
                                        ? t('list.ordered_date') +
                                          ': ' +
                                          formatDate(
                                              list.orderedDate,
                                              'DD.MM.YYYY'
                                          )
                                        : t('list.due_date') +
                                          ': ' +
                                          formatDate(
                                              list.dueDate,
                                              'DD.MM.YYYY HH:mm'
                                          )}
                                </Typography>
                                <Typography variant="body2">
                                    {t('list.expected_delivery_date') + ': '}
                                    {formatDate(list.expectedDeliveryDate)}
                                </Typography>
                            </Grid2>
                        )}
                        {!list.template && (
                            <Grid2
                                md={1}
                                xs={2}
                                order={{ xs: 3, md: 4 }}
                                className="flex-center"
                            >
                                {moment(list.expectedDeliveryDate) >
                                    moment() && (
                                    <RemindersIconButton
                                        listId={props.list.id}
                                        pastOrder={props.list.ordered}
                                        dueDate={list.dueDate}
                                        expectedDeliveryDate={
                                            list.expectedDeliveryDate
                                        }
                                    />
                                )}
                            </Grid2>
                        )}
                        <Grid2
                            md={1}
                            xs={2}
                            order={{ xs: 3, md: 4 }}
                            className="flex-center"
                        >
                            <CopyListMenu listId={props.list.id} />
                        </Grid2>
                    </Grid2>
                    {(isAdmin(user) || isEventOrganizer(user)) &&
                        list.event &&
                        !list.template && (
                            <Grid2 container justifyContent="center">
                                <Grid2>
                                    {!list.ordered ? (
                                        <Typography
                                            sx={{
                                                marginTop: 2,
                                                marginBottom: 1,
                                            }}
                                        >
                                            {t('list.people_amount')}:
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            {t('list.people_amount')}:{' '}
                                            {list.peopleAmount}
                                        </Typography>
                                    )}
                                </Grid2>
                                <Grid2 marginTop="10px">
                                    {!list.ordered && (
                                        <TextField
                                            id="outlined-number"
                                            style={{ width: 75 }}
                                            type="number"
                                            size="small"
                                            value={peopleAmount}
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: 'center' },
                                            }}
                                            fullWidth
                                            onChange={handleInputChange}
                                            onBlur={handlePeopleAmountChange}
                                        />
                                    )}
                                </Grid2>
                            </Grid2>
                        )}
                    <Box className={styles.shoppingListHeader}>
                        <Box className={getListClassNames()}>
                            <Box></Box>
                            <Box>
                                <SortButton
                                    sortOptions={sortOptions}
                                    setSortOptions={setSortOptions}
                                    columnSortType={SortType.Name}
                                    columnName={t('list.item')}
                                ></SortButton>
                            </Box>
                            <Box
                                className="flex-center"
                                display={{ xs: 'none', sm: 'flex' }}
                            >
                                <FilterMenu
                                    allStores={allStores}
                                    filteredItems={filteredItems}
                                    setFilterOptions={setFilterOptions}
                                ></FilterMenu>
                                <SortButton
                                    sortOptions={sortOptions}
                                    setSortOptions={setSortOptions}
                                    columnSortType={SortType.Store}
                                    columnName={t('list.store')}
                                ></SortButton>
                            </Box>
                            <Box
                                className={'flex-center'}
                                sx={{ paddingLeft: '1rem' }}
                            >
                                <SortMenu
                                    sortTypes={[
                                        SortType.Likes,
                                        SortType.Popularity,
                                    ]}
                                    sortOptions={sortOptions}
                                    setSortOptions={setSortOptions}
                                    columnName={t('list.likes')}
                                    filteredItems={filteredItems}
                                    setFilterOptions={setFilterOptions}
                                ></SortMenu>
                            </Box>
                            {(isAdmin(user) ||
                                isEventOrganizer(user) ||
                                list.ordered) && (
                                <Box
                                    display={
                                        isAdmin(user) &&
                                        !list.ordered &&
                                        list.template
                                            ? list.event
                                                ? { xs: 'flex', sm: 'flex' }
                                                : { xs: 'none', sm: 'flex' }
                                            : { xs: 'flex' }
                                    }
                                    className={'flex-center'}
                                >
                                    <Typography
                                        variant="body1"
                                        fontWeight="600"
                                        color="text.primary"
                                    >
                                        {list.event && list.template
                                            ? t('item.per-person')
                                            : t('list.quantity')}
                                    </Typography>
                                </Box>
                            )}
                            {(isAdmin(user) || isEventOrganizer(user)) &&
                                list.template &&
                                list.event && (
                                    <Box
                                        display={{ xs: 'none', sm: 'flex' }}
                                        className={'flex-center'}
                                    >
                                        <Typography
                                            variant="body1"
                                            fontWeight="600"
                                            color="text.primary"
                                        >
                                            {t('item.avg-per-person')}
                                        </Typography>
                                    </Box>
                                )}
                            {(isAdmin(user) || isEventOrganizer(user)) &&
                                !list.ordered &&
                                !list.template && (
                                    <Box
                                        display={{ xs: 'none', sm: 'flex' }}
                                        className={'flex-center'}
                                    >
                                        <Typography
                                            variant="body1"
                                            fontWeight="600"
                                            color="text.primary"
                                        >
                                            {t('list.done')}
                                        </Typography>
                                    </Box>
                                )}
                        </Box>
                    </Box>
                    <List className="full-width">
                        {sortedItems.length > 0 ? (
                            sortedItems.map((it, i) => (
                                <ShoppingListItem
                                    item={it}
                                    key={i}
                                    pastOrder={list.ordered}
                                    isEventListItem={list.event}
                                    isEventTemplateItem={
                                        list.template && list.event
                                    }
                                    isPastDueDate={moment(
                                        list.dueDate,
                                        true
                                    ).isBefore(new Date())}
                                />
                            ))
                        ) : (
                            <Typography
                                variant="body1"
                                align="center"
                                sx={{
                                    marginTop: '15px',
                                }}
                            >
                                {list.items.length > 0
                                    ? t('list.no_list_items_filtered')
                                    : list.template
                                    ? t('list.no_template_items')
                                    : t('list.no_list_items')}
                                <br></br>
                                <SentimentDissatisfiedIcon
                                    color="action"
                                    fontSize="large"
                                    sx={{
                                        marginTop: '15px',
                                    }}
                                ></SentimentDissatisfiedIcon>
                            </Typography>
                        )}
                    </List>
                </div>
            )}
        </div>
    );
};

export default ShoppingListTab;
