import { useState, MouseEvent } from 'react';
import {
    Menu,
    MenuItem,
    Typography,
    Checkbox,
    IconButton,
} from '@mui/material';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ItemDto } from '../../models/shopping-list/ItemDto';
import { getAllStores } from '../../utility/filter-helper';
import { FilterAlt, FilterAltOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface FilterMenuProps {
    allStores: String[];
    filteredItems: ItemDto[];
    setFilterOptions: (options: String[]) => void;
}

const FilterMenu = (props: FilterMenuProps): JSX.Element => {
    const { allStores, filteredItems, setFilterOptions } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { t } = useTranslation();

    let filteredStores: String[] = getAllStores(filteredItems);

    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        if (allStores.length > 0) setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleStoreClickedMenuItem = (
        event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>
    ): void => {
        const store = getStoreName(event.currentTarget.innerText);
        if (filteredStores.includes(store)) {
            filteredStores.splice(filteredStores.indexOf(store), 1);
        } else {
            filteredStores = [...filteredStores, store];
        }
        setFilterOptions(filteredStores);
    };

    const getStoreName = (elemText: string): string => {
        const emptyShop = t('item.shopNameEmpty');
        if (elemText === emptyShop) return '';
        else return elemText;
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                {filteredStores.length !== allStores.length ? (
                    <FilterAlt></FilterAlt>
                ) : (
                    <FilterAltOutlined></FilterAltOutlined>
                )}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    sx: {
                        '& .MuiMenuItem-root.Mui-selected': {
                            backgroundColor: 'transparent',
                        },
                        '& .MuiMenuItem-root:hover': {
                            backgroundColor: 'transparent',
                        },
                        '& .MuiMenuItem-root.Mui-selected:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                }}
            >
                {allStores.map((item, index) => (
                    <MenuItem key={index} onClick={handleStoreClickedMenuItem}>
                        <Checkbox
                            value={item}
                            icon={<RadioButtonUncheckedOutlinedIcon />}
                            checkedIcon={<CheckCircleOutlineIcon />}
                            checked={filteredStores.includes(item)}
                            onChange={(e) => e.preventDefault()}
                            disableRipple={true}
                        ></Checkbox>
                        <Typography
                            variant="body1"
                            fontWeight="normal"
                            color="text.primary"
                        >
                            {item === '' ? t('item.shopNameEmpty') : item}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
export default FilterMenu;
