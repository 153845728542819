import { Box, Button, IconButton, List, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../components/main-layout/MainLayout';
import { ScraperConfigDto } from '../../models/shopping-list/ScraperConfigDto';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    getScrapeConfigs,
    removeScrapeConfig,
    selectScrapeConfigs,
    updateScrapeConfig,
} from '../../store/settings/scrape-config-slice';
import { useEffect, useState } from 'react';
import ScrapeConfigItem from '../../components/store-search/ScrapeConfigItem';
import { ArrowBackIosNew } from '@mui/icons-material';
import ModifyStoreSearchForm from '../../components/store-search/ModifyStoreSearchForm';
import { unwrapResult } from '@reduxjs/toolkit';
import { showSuccessSnackBar } from '../../store/ui/ui-slice';

const SettingsManagement = (props: any): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedConfig, setSelectedConfig] =
        useState<ScraperConfigDto | null>(null);
    const [creatingNew, setCreatingNew] = useState(false);

    const allScrapeConfigs = useAppSelector(selectScrapeConfigs);

    const fetchScrapeConfigs = async (): Promise<void> => {
        if (!allScrapeConfigs || allScrapeConfigs.length === 0) {
            await dispatch(getScrapeConfigs());
        }
    };

    const onDeleteScrapeConfig = async (
        toDelete: ScraperConfigDto
    ): Promise<void> => {
        await dispatch(removeScrapeConfig(toDelete.storeName));
        await showSuccessSnackBar(
            t('actions.search_config_removal_successful')
        );
    };

    useEffect(() => {
        void fetchScrapeConfigs();
    }, []);

    // Update a store scrape configuration on the backend and refresh the page.
    const updateStoreSearchConfig: SubmitHandler<ScraperConfigDto> = async (
        data
    ) => {
        const updatedConfig = unwrapResult(
            await dispatch(updateScrapeConfig(data))
        );
        if (creatingNew) {
            await showSuccessSnackBar(
                t('actions.search_config_creation_successful')
            );
            setCreatingNew(false);
        } else {
            setSelectedConfig(updatedConfig);
            await showSuccessSnackBar(
                t('actions.search_config_update_successful')
            );
        }
    };

    return (
        <MainLayout width="60em">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid2
                    rowSpacing={0}
                    justifyContent={'center'}
                    container
                    xs={12}
                    spacing={1}
                    maxWidth={600}
                >
                    <Grid2 marginBottom={4} xs={12} className="flex-center">
                        <Typography variant="h1">
                            {t('management.app_settings_management')}
                        </Typography>
                    </Grid2>

                    {!selectedConfig && !creatingNew && (
                        <>
                            <Grid2 rowSpacing={0} xs={12}>
                                <Grid2 marginBottom={2} xs={12}>
                                    <Typography
                                        textAlign={'center'}
                                        variant="h2"
                                    >
                                        {'Configure store search'}
                                    </Typography>
                                </Grid2>
                                <List className="full-width">
                                    {allScrapeConfigs?.map(
                                        (scrapeConfig, i) => {
                                            return (
                                                <ScrapeConfigItem
                                                    key={`${scrapeConfig.storeName}-${i}`}
                                                    handleDelete={
                                                        onDeleteScrapeConfig
                                                    }
                                                    handleEdit={() => {
                                                        setCreatingNew(false);
                                                        setSelectedConfig(
                                                            scrapeConfig
                                                        );
                                                    }}
                                                    config={scrapeConfig}
                                                />
                                            );
                                        }
                                    )}
                                </List>
                            </Grid2>

                            <Grid2 marginTop={4} maxWidth={200} xs={12}>
                                <Button
                                    onClick={() => setCreatingNew(true)}
                                    type="submit"
                                    variant="contained"
                                >
                                    Add new store
                                </Button>
                            </Grid2>
                        </>
                    )}
                    {(selectedConfig ?? creatingNew) && (
                        <>
                            <Grid2 rowSpacing={0} xs={12}>
                                <Box
                                    sx={{
                                        alignContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                    marginBottom={2}
                                >
                                    <IconButton
                                        onClick={() => {
                                            setSelectedConfig(null);
                                            setCreatingNew(false);
                                        }}
                                        color="secondary"
                                    >
                                        <ArrowBackIosNew />
                                    </IconButton>
                                    <Typography
                                        textAlign={'center'}
                                        variant="h2"
                                    >
                                        {!creatingNew
                                            ? `Edit configuration for ${
                                                  selectedConfig?.storeName as string
                                              }`
                                            : 'New store search configuration'}
                                    </Typography>
                                </Box>
                                <ModifyStoreSearchForm
                                    onSubmit={updateStoreSearchConfig}
                                    initialValues={selectedConfig}
                                    addNew={creatingNew}
                                />
                            </Grid2>
                        </>
                    )}
                </Grid2>
            </div>
        </MainLayout>
    );
};

export default SettingsManagement;
