import { ItemDto } from '../models/shopping-list/ItemDto';

const IMAGE_MAX_WIDTH = 500;
const IMAGE_MAX_HEIGHT = 500;

export const blobToDataUrl = async (file: File | Blob): Promise<string> =>
    await new Promise((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const dataUrlToBase64 = (dataUrl: string): string => {
    return dataUrl.split(',')[1];
};

export const imgToDataUrl = async (
    src: string,
    limitScale: boolean
): Promise<string> =>
    await new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = src;
        image.onload = () => {
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            const context: CanvasRenderingContext2D | null =
                canvas.getContext('2d');

            const [width, height] = limitScale
                ? getDownScaledImageDimensions(image)
                : [image.naturalWidth, image.naturalHeight];
            canvas.width = width;
            canvas.height = height;

            if (context !== null) {
                context.drawImage(image, 0, 0, width, height);
                const dataUrl = canvas.toDataURL('image/png');
                resolve(dataUrl);
            } else {
                reject(new Error('Canvas context is null!'));
            }
        };
        image.onerror = (error) => reject(error);
    });

export const compressDataUrl = async (src: string): Promise<string> =>
    await new Promise((resolve, reject) => {
        const image = new Image();
        image.src = src;
        image.onload = () => {
            const [width, height] = getDownScaledImageDimensions(image);
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            const context: CanvasRenderingContext2D | null =
                canvas.getContext('2d');
            canvas.width = width;
            canvas.height = height;

            if (context !== null) {
                context.drawImage(image, 0, 0, width, height);
                const dataUrl = canvas.toDataURL('image/jpeg');
                resolve(dataUrl);
            } else {
                reject(new Error('Canvas context is null!'));
            }
        };
        image.onerror = (error) => reject(error);
    });

export const itemBase64ToImage = (item: ItemDto): string => {
    if (item.image === undefined || item.image === '') return '';
    return getImageBase64Prefix() + item.image;
};

export const getImageBase64Prefix = (): string => {
    return 'data:image/jpg;base64,';
};

const getDownScaledImageDimensions = (
    image: HTMLImageElement
): [number, number] => {
    let w = image.width;
    let h = image.height;

    if (w > h && w > IMAGE_MAX_WIDTH) {
        h = Math.round((h * IMAGE_MAX_WIDTH) / w);
        w = IMAGE_MAX_WIDTH;
    } else if (h > w && h > IMAGE_MAX_HEIGHT) {
        w = Math.round((w * IMAGE_MAX_HEIGHT) / h);
        h = IMAGE_MAX_HEIGHT;
    }

    return [w, h];
};
