import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { refreshTokenAsync } from '../../store/auth/auth-slice';
import { useAppDispatch } from '../../store/hooks';
import { getCurrentUserAsync } from '../../store/user/user-slice';

enum SessionOptions {
    AzureLogin = 'azureAdLogin',
    AzureLogout = 'azureAdLogout',
    CookieExpiry = 'expires',
}

const SessionGuard = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const getRedirect = (): string | undefined => {
        const from = location.state?.from as Location | undefined;
        if (from) {
            return from.pathname + from.search;
        }
        return undefined;
    };

    const checkAzureExpiry = (): boolean => {
        const azureLoginExpires = localStorage.getItem('azureLoginExpires');
        const azureLoginExpiresNum = Number(azureLoginExpires);
        const timeNow = Date.now();
        if (azureLoginExpires && azureLoginExpiresNum > timeNow) {
            return true;
        }
        throw Error();
    };

    useEffect(() => {
        const restoreUserSession = async (): Promise<void> => {
            const expiredToken = localStorage.getItem('authToken');
            const azureLoginExpires = localStorage.getItem('azureLoginExpires');
            const redirect = getRedirect();
            let isSuccess = false;
            if (expiredToken ?? azureLoginExpires) {
                try {
                    // Regular login.
                    if (!azureLoginExpires && expiredToken) {
                        unwrapResult(
                            await dispatch(refreshTokenAsync({ expiredToken }))
                        );

                        // Azure AD login. Throws error and redirects if expired.
                    } else {
                        checkAzureExpiry();
                    }
                    unwrapResult(await dispatch(getCurrentUserAsync()));
                    isSuccess = true;
                    navigate(redirect ?? '/home');
                } catch {}
            }

            if (!isSuccess) {
                navigate(redirect ? `/login?redirect=${redirect}` : `/login`);
            }
        };

        // Handle authentication related redirect actions based on query params.
        const queryParams = new URLSearchParams(location.search);

        // Handle user login redirect. Clear local storage and take the user back to the login page.
        if (queryParams.get(SessionOptions.AzureLogin)) {
            const expiry = queryParams.get(SessionOptions.CookieExpiry);
            if (expiry) {
                localStorage.setItem('azureLoginExpires', expiry);
                navigate('/home');
                return;
            }
            // Some error with the redirect, just take the user back to the login. TODO: maybe show error message.
            localStorage.removeItem('azureLoginExpires');
            navigate('/login');
            return;
        }

        // Handle user logout redirect. Clear local storage and take the user back to the login page.
        if (queryParams.get(SessionOptions.AzureLogout)) {
            localStorage.removeItem('azureLoginExpires');
            navigate('/login');
            return;
        }

        void restoreUserSession();
    }, []);

    return <></>;
};

export default SessionGuard;
