import { Edit, Check } from '@mui/icons-material';
import { IconButton, ListItem, Tooltip, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserDto } from '../../models/user/UserDto';

interface UserItemProps {
    user: UserDto;
}

const UserItem = (props: UserItemProps): JSX.Element => {
    const { t } = useTranslation();
    const { user } = props;
    const navigate = useNavigate();

    return (
        <ListItem divider={true}>
            <Grid2 container xs={12}>
                <Grid2 xs={5}>
                    <Grid2 container xs={12}>
                        <Grid2 xs={12}>
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                            >{`${user.lastName}, ${user.firstName}`}</Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Typography variant="body2">
                                {user.email}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Typography variant="body2">
                                {user.homeOffice.name}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 xs={3} className="flex-center" flexDirection="column">
                    {user.userRoles.map((it, i) => (
                        <Typography key={i} align={'center'}>
                            {it.roleName}
                        </Typography>
                    ))}
                </Grid2>
                <Grid2 xs={3} className="flex-center" flexDirection="column">
                    {user.azureUser && (
                        <Tooltip title={t('user.azure_user_explanation')}>
                            <Check />
                        </Tooltip>
                    )}
                </Grid2>
                <Grid2 xs={1} className="flex-center">
                    <IconButton
                        onClick={() => navigate(`/user/${user.id}/edit`)}
                    >
                        <Edit />
                    </IconButton>
                </Grid2>
            </Grid2>
        </ListItem>
    );
};

export default UserItem;
