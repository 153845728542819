import { Typography } from '@mui/material';
import { useState } from 'react';
import styles from './ExpandableText.module.css';

interface ExpandableTextProps {
    maxWords: number;
    wordMaxLen: number;
    text: string;
}

const ExpandableText = (props: ExpandableTextProps): JSX.Element => {
    const { maxWords, wordMaxLen, text } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = (): void => {
        setIsExpanded(!isExpanded);
    };

    // Cuts super long words to preserve a decent look for the product page
    const getWordsLimitToLen = (text: string, length: number): string[] => {
        const textWords = text.split(' ');

        return textWords.map((w) =>
            w.length > length ? w.substring(0, length) + '.' : w
        );
    };

    const words = getWordsLimitToLen(text, wordMaxLen);

    if (words.length > maxWords) {
        return (
            <Typography
                className={styles.expandable}
                variant="body2"
                onClick={toggleExpanded}
            >
                {isExpanded ? text : words.slice(0, maxWords).join(' ') + '...'}
            </Typography>
        );
    }
    return <Typography variant="body2">{words.join(' ')}</Typography>;
};
export default ExpandableText;
