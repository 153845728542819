import {
    Button,
    FormLabel,
    TextField,
    Tabs,
    Tab,
    Paper,
    Typography,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    ListSubheader,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import styles from './ShoppingListForm.module.css';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { ShoppingListDto } from '../../models/shopping-list/ShoppingListDto';
import { ModifyShoppingListDto } from '../../models/shopping-list/ModifyShoppingListDto';
import { selectTemplates } from '../../store/shopping-list/shopping-list-slice';
import React, { ChangeEvent, useEffect, useState } from 'react';
import TabPanel from '../tab-panel/TabPanel';
import Markdown from '../markdown/Markdown';
import { useLocation, useNavigate } from 'react-router-dom';
import TemplatesItemsTable from '../template-items-table/TemplateItemsTable';
import { TemplateType } from '../../models/shopping-list/TemplateTypeEnum';
import { ItemDto } from '../../models/shopping-list/ItemDto';

interface ShoppingListFormProps {
    initialValues?: ShoppingListDto;
    onSubmit?: SubmitHandler<ModifyShoppingListDto>;
    onDelete?: SubmitHandler<ShoppingListDto>;
    isTemplate?: boolean;
    isEdit?: boolean;
}

const ShoppingListForm = (props: ShoppingListFormProps): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { initialValues, isTemplate, isEdit } = props;
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [selectedTemplate, setSelectedTemplate] = useState<ShoppingListDto>();
    const [isTemplateSelected, setIsTemplateSelected] = useState<boolean>();
    const [selectedTemplateType, setSelectedTemplateType] = useState<string>(
        initialValues?.event ? TemplateType.Event : TemplateType.Normal
    );
    const [peopleAmount, setPeopleAmount] = useState<number>(0);
    const [eventItems, setEventItems] = useState<ItemDto[]>();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const location = useLocation();
    const templates = useAppSelector(selectTemplates);

    const defaultValues: Partial<ModifyShoppingListDto> = initialValues ?? {
        name: '',
        comment: '',
        peopleAmount: 0,
        dueDate: null,
        expectedDeliveryDate: null,
        items: [],
        template: false,
        event: false,
    };

    const {
        control,
        handleSubmit,
        reset,
        trigger,
        watch,
        formState: { isValid, errors },
    } = useForm<ModifyShoppingListDto>({
        defaultValues,
        mode: 'onChange',
    });

    useEffect(() => {
        reset(defaultValues);
        if (initialValues && typeof initialValues.event !== 'undefined') {
            setSelectedTemplateType(
                initialValues.event ? TemplateType.Event : TemplateType.Normal
            );
        }
    }, [initialValues]);

    const onSubmit: SubmitHandler<ModifyShoppingListDto> = async (data) => {
        setIsDisabled(true);
        if (location.pathname.includes('/templates')) {
            data.template = true;
            if (selectedTemplateType === TemplateType.Event) {
                data.event = true;
            } else {
                data.event = false;
            }
        }

        if (location.pathname.includes('/orders/add')) {
            if (selectedTemplate?.event) {
                data.event = true;
                data.items = eventItems;
                data.peopleAmount = peopleAmount;
            } else {
                data.items = selectedTemplate?.items;
            }
        }

        const response = await dispatch(() => props.onSubmit?.(data));

        if (response) {
            navigate(location.pathname.split('/', 2).join('/'));
        } else {
            setIsDisabled(false);
        }
    };

    const onDelete = async (): Promise<void> => {
        if (initialValues) {
            await props.onDelete?.(initialValues);
        }
    };

    const handleChange = (_: React.SyntheticEvent, value: number): void => {
        setTabIndex(value);
    };

    const handleTemplateChange = (event: any): void => {
        if (event.target.value !== '') {
            setSelectedTemplate(
                templates.find((t) => t.id === event.target.value)
            );
            setIsTemplateSelected(true);
        } else {
            reset(defaultValues);
            setSelectedTemplate(undefined);
            setIsTemplateSelected(false);
        }
    };

    const handlePeopleAmountChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        event.target.focus();
        const value = Number(event.target.value);
        if (value >= 0) {
            setPeopleAmount(Number(value));
            if (selectedTemplate) {
                const items = JSON.parse(
                    JSON.stringify(selectedTemplate.items)
                );
                setEventItems(handleEventItems(value, items));
            }
        } else {
            setPeopleAmount(0);
        }
    };

    const handleEventItems = (pAmount: number, items: ItemDto[]): ItemDto[] => {
        for (const item of items) {
            if (item.perPerson !== 0) {
                item.amountOrdered = Math.ceil(pAmount * item.perPerson);
            }
            item.eventTemplateItemId = item.id;
        }
        return items;
    };

    const watchDates = watch(['dueDate', 'expectedDeliveryDate']);

    const dateValidation = (value: moment.MomentInput): boolean => {
        const dueDate = moment(watchDates[0], true);
        const expectedDeliveryDate = moment(value, true);
        if (expectedDeliveryDate.isBefore(dueDate)) {
            return false;
        }
        return true;
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            },
        },
    };

    useEffect(() => {
        void trigger(['dueDate', 'expectedDeliveryDate']);
    }, [watchDates[0], watchDates[1]]);

    useEffect(() => {
        reset(defaultValues, { keepDirtyValues: true });
    }, [initialValues]);

    useEffect(() => {
        if (selectedTemplate?.event) {
            const items = JSON.parse(JSON.stringify(selectedTemplate.items));
            setEventItems(
                handleEventItems(selectedTemplate.peopleAmount, items)
            );
        }
    }, [selectedTemplate]);

    const templateGroups = Object.values(TemplateType).map((type, typeKey) => {
        const groupTemplates = templates.filter(
            (t) => Number(t.event) === typeKey
        );
        return [
            <ListSubheader key={`header-${typeKey}`} color="inherit">
                {type}
            </ListSubheader>,
            ...groupTemplates.map((t) => (
                <MenuItem key={`item-${t.id}`} value={t.id}>
                    {t.name}
                </MenuItem>
            )),
        ].flat();
    });

    return (
        <Grid2 container spacing={2}>
            {
                <form
                    id="addshoppinglist-form"
                    className={styles.addShoppingListForm}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid2 xs={12}>
                        {!isEdit && !isTemplate && (
                            <>
                                <FormLabel
                                    className={styles.label}
                                    id="template"
                                >
                                    {t('list.template')}
                                </FormLabel>
                                <Select
                                    onChange={handleTemplateChange}
                                    fullWidth
                                    defaultValue=""
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="">
                                        <em>{t('general.none')}</em>
                                    </MenuItem>
                                    {templateGroups}
                                </Select>
                            </>
                        )}
                        {isTemplateSelected && (
                            <>
                                <FormLabel
                                    className={styles.label}
                                    sx={{ marginTop: 2, marginBottom: 1 }}
                                >
                                    {t('list.template_items')}
                                </FormLabel>
                                <TemplatesItemsTable
                                    template={selectedTemplate}
                                    eventItems={eventItems}
                                />
                            </>
                        )}
                        {selectedTemplate?.event &&
                            selectedTemplate.items.length !== 0 && (
                                <Grid2
                                    container
                                    marginTop="5px"
                                    justifyContent="space-between"
                                >
                                    <Grid2>
                                        <FormLabel
                                            className={styles.label}
                                            sx={{
                                                marginTop: 2,
                                                marginBottom: 1,
                                            }}
                                        >
                                            {t('list.people_amount')}
                                        </FormLabel>
                                    </Grid2>
                                    <Grid2 marginTop="10px">
                                        <TextField
                                            id="outlined-number"
                                            style={{ width: 75 }}
                                            type="number"
                                            size="small"
                                            value={peopleAmount}
                                            inputProps={{
                                                min: 0,
                                                style: {
                                                    textAlign: 'center',
                                                },
                                            }}
                                            fullWidth
                                            onChange={handlePeopleAmountChange}
                                        />
                                    </Grid2>
                                </Grid2>
                            )}
                    </Grid2>
                    <Grid2 xs={12}>
                        <FormLabel className={styles.label} id="list-name">
                            {isTemplate
                                ? t('list.template_name')
                                : t('list.list_name')}
                        </FormLabel>
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('errors.required'),
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    error={!!errors.name}
                                    aria-labelledby="first-name"
                                    helperText={errors.name?.message}
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid2>

                    {isTemplate && (
                        <Grid2 xs={12}>
                            <FormLabel
                                className={styles.label}
                                id="template-type"
                            >
                                {t('list.template_type')}
                            </FormLabel>
                            <RadioGroup
                                value={selectedTemplateType}
                                onChange={(e) => {
                                    setSelectedTemplateType(e.target.value);
                                }}
                            >
                                {Object.values(TemplateType).map(
                                    (value, key) => (
                                        <FormControlLabel
                                            key={key}
                                            value={value}
                                            control={
                                                <Radio sx={{ color: 'grey' }} />
                                            }
                                            label={value}
                                        />
                                    )
                                )}
                            </RadioGroup>
                        </Grid2>
                    )}

                    <Grid2 xs={12}>
                        <FormLabel className={styles.label} id="comment">
                            {t('list.comment')}
                        </FormLabel>
                        <Tabs
                            color="primary"
                            value={tabIndex}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label={t('actions.input')} />
                            <Tab label={t('actions.preview')} />
                        </Tabs>
                        <TabPanel index={0} value={tabIndex}>
                            <Controller
                                name="comment"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.comment}
                                        aria-labelledby="comment"
                                        helperText={errors.comment?.message}
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={4}
                                    />
                                )}
                            />
                        </TabPanel>
                        <TabPanel index={1} value={tabIndex}>
                            <Paper
                                className={styles.previewTab}
                                variant="outlined"
                            >
                                <Markdown>{watch('comment')}</Markdown>
                            </Paper>
                        </TabPanel>
                        <Typography variant="body2">
                            {t('general.support_markdown')}
                            <br />
                            <a
                                target="_blank"
                                href="https://www.markdownguide.org/cheat-sheet"
                                rel="noreferrer"
                            >
                                {t('general.markdown_cheatsheet_link')}
                            </a>
                        </Typography>
                    </Grid2>
                    {!isTemplate ? (
                        <Grid2 xs={12}>
                            <FormLabel className={styles.label} id="due-date">
                                {t('list.due_date')}
                            </FormLabel>
                            <Controller
                                name="dueDate"
                                control={control}
                                render={({ field }) => (
                                    <DateTimePicker
                                        onChange={(date) =>
                                            field.onChange(
                                                moment(date).toISOString(true)
                                            )
                                        }
                                        value={field.value}
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                error={!!errors.dueDate}
                                                fullWidth
                                            />
                                        )}
                                        disablePast={true}
                                        ampm={false}
                                        inputFormat="DD.MM.yyyy HH:mm"
                                        disableMaskedInput={true}
                                    />
                                )}
                            />
                        </Grid2>
                    ) : (
                        <></>
                    )}
                    {!isTemplate ? (
                        <Grid2 xs={12}>
                            <FormLabel
                                className={styles.label}
                                id="expected-delivery-date"
                            >
                                {t('list.expected_delivery_date')}
                            </FormLabel>
                            <Controller
                                name="expectedDeliveryDate"
                                control={control}
                                rules={{
                                    validate: {
                                        validExpectedDate: (value) =>
                                            dateValidation(value) ||
                                            t('errors.expected_after_due'),
                                    },
                                }}
                                render={({ field }) => (
                                    <DatePicker
                                        onChange={(date) =>
                                            field.onChange(
                                                moment(date).toISOString(true)
                                            )
                                        }
                                        minDate={moment(watch('dueDate'))}
                                        value={field.value}
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                sx={{ width: '100%' }}
                                                error={
                                                    !!errors.expectedDeliveryDate
                                                }
                                                helperText={
                                                    errors.expectedDeliveryDate
                                                        ?.message
                                                }
                                            />
                                        )}
                                        disablePast={true}
                                        inputFormat="DD.MM.yyyy"
                                        disableMaskedInput={true}
                                    />
                                )}
                            />
                        </Grid2>
                    ) : (
                        <></>
                    )}
                    <Grid2 xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!isValid || isDisabled}
                            fullWidth
                        >
                            {initialValues
                                ? t('actions.save')
                                : isTemplate
                                ? t('actions.add_new_template')
                                : t('actions.add_new_list')}
                        </Button>
                    </Grid2>
                    {initialValues && (
                        <Grid2 xs={12}>
                            <Button
                                variant="contained"
                                color="error"
                                fullWidth
                                onClick={onDelete}
                            >
                                {isTemplate
                                    ? t('actions.remove_template')
                                    : t('actions.remove_list')}
                            </Button>
                        </Grid2>
                    )}
                </form>
            }
        </Grid2>
    );
};

export default ShoppingListForm;
