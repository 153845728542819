import {
    Box,
    Checkbox,
    ListItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    ThumbUpAlt,
    ThumbUpOffAlt,
    ThumbDownAlt,
    ThumbDownOffAlt,
} from '@mui/icons-material';
import { ItemDto } from '../../models/shopping-list/ItemDto';
import ShoppingListItemActions from '../shopping-list-item-actions/ShoppingListItemActions';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    selectInactiveLists,
    setCheckStatusAsync,
    setLikeStatusAsync,
    setDislikeStatusAsync,
    setOrderedAmountAsync,
    setPerPersonAmountAsync,
} from '../../store/shopping-list/shopping-list-slice';
import { selectCurrentUser } from '../../store/user/user-slice';
import {
    hasUserLikedItem,
    hasUserDislikedItem,
    isAdmin,
    isEventOrganizer,
} from '../../utility/user-helper';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import styles from './ShoppingListItem.module.css';
import ShoppingListItemImage from '../shopping-list-item-image/ShoppingListItemImage';
import { useLocation } from 'react-router-dom';
import { wilson } from '../../utility/sort-helper';
import ExpandableText from '../expandable-text/ExpandableText';

interface ShoppingListItemProps {
    item: ItemDto;
    pastOrder: boolean;
    isPastDueDate: boolean;
    isEventListItem: boolean;
    isEventTemplateItem: boolean;
}

const ShoppingListItem = (props: ShoppingListItemProps): JSX.Element => {
    const {
        item,
        pastOrder,
        isPastDueDate,
        isEventListItem,
        isEventTemplateItem,
    } = props;
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectCurrentUser);
    const orderedLists = useAppSelector(selectInactiveLists);
    const [amount, setAmount] = useState<string | number>(item.amountOrdered);
    const [perPerson, setPerPerson] = useState<string | number>(item.perPerson);
    const [pastAverage, setPastAverage] = useState<string | number>();

    const handleItemLike = async (
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ): Promise<void> => {
        const dislike = (
            (event.target.parentElement as HTMLElement)
                .parentElement as HTMLElement
        ).children[2].children[0] as HTMLInputElement;
        // Disable the button until dispatch resolve to avoid duplicate clicks
        event.target.disabled = true;
        dislike.disabled = true;
        try {
            await dispatch(
                setLikeStatusAsync({ data: checked, itemId: item.id })
            ).unwrap();
            await dispatch(
                setDislikeStatusAsync({ data: false, itemId: item.id })
            ).unwrap();
        } finally {
            event.target.disabled = false;
            dislike.disabled = false;
        }
    };

    const handleItemDislike = async (
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ): Promise<void> => {
        const like = (
            (event.target.parentElement as HTMLElement)
                .parentElement as HTMLElement
        ).children[0].children[0] as HTMLInputElement;
        // Disable the button until dispatch resolve to avoid duplicate clicks
        event.target.disabled = true;
        like.disabled = true;
        try {
            await dispatch(
                setDislikeStatusAsync({ data: checked, itemId: item.id })
            ).unwrap();
            await dispatch(
                setLikeStatusAsync({ data: false, itemId: item.id })
            ).unwrap();
        } finally {
            event.target.disabled = false;
            like.disabled = false;
        }
    };

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        e.target.focus();
        if (e.target.value === '') {
            setAmount('');
        } else {
            setAmount(Number(e.target.value));
        }
    };

    const handleQuantityChange = async (event: any): Promise<void> => {
        const value = Number(event.target.value);
        if (value >= 0) {
            try {
                await dispatch(
                    setOrderedAmountAsync({
                        data: {
                            itemId: item.id,
                            amountOrdered: Math.round(value),
                        },
                        listId: item.shoppingListId,
                    })
                ).unwrap();
            } catch {}
        } else {
            setAmount(0);
        }
    };

    const handlePerPersonInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        e.target.focus();
        if (e.target.value === '') {
            setPerPerson('');
        } else {
            setPerPerson(Number(e.target.value));
        }
    };

    const handlePerPersonAmountChange = async (event: any): Promise<void> => {
        const value = Number(event.target.value);
        if (value >= 0) {
            try {
                await dispatch(
                    setPerPersonAmountAsync({
                        data: {
                            itemId: item.id,
                            perPerson: value,
                        },
                        listId: item.shoppingListId,
                    })
                ).unwrap();
            } catch {}
        } else {
            setPerPerson(0);
        }
    };

    const handleItemCheck = async (
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ): Promise<void> => {
        // Disable the button until dispatch resolve to avoid duplicate clicks
        event.target.disabled = true;
        try {
            await dispatch(
                setCheckStatusAsync({
                    data: {
                        itemId: item.id,
                        isChecked: checked,
                    },
                    listId: item.shoppingListId,
                })
            ).unwrap();
        } finally {
            event.target.disabled = false;
        }
    };

    useEffect(() => {
        setAmount(item.amountOrdered);
        setPerPerson(item.perPerson);
        setPastAverage(getPastAverage());
    }, [item]);

    const getPastAverage = (): string | number => {
        if (!isEventTemplateItem || orderedLists.length === 0) {
            return '-';
        }
        const matchingItems: ItemDto[] = orderedLists
            .flatMap((list) => list.items)
            .filter((i) => i.eventTemplateItemId === item.id);

        const sumPerPerson = matchingItems.reduce(
            (total, i) => total + i.perPerson,
            0
        );

        return (
            parseFloat((sumPerPerson / matchingItems.length).toFixed(2)) || '-'
        );
    };

    const getLikeAmount = (): JSX.Element => {
        return (
            <Tooltip
                title={`${t('list.popularity')}: ${wilson(item)}`}
                enterDelay={600}
                enterNextDelay={600}
            >
                <Typography variant="body1">
                    {item.usersWhoLiked.length}
                </Typography>
            </Tooltip>
        );
    };

    const getDislikeAmount = (): JSX.Element => {
        return (
            <Tooltip
                title={`${t('list.popularity')}: ${wilson(item)}`}
                enterDelay={600}
                enterNextDelay={600}
            >
                <Typography variant="body1">
                    {item.usersWhoDisliked.length}
                </Typography>
            </Tooltip>
        );
    };

    function getListClassNames(itemClass?: string): string {
        const classNames: string[] = [];
        classNames.push(styles.listGrid);
        itemClass && classNames.push(itemClass);
        pastOrder && classNames.push(styles.pastOrder);
        location.pathname.includes('template') &&
            classNames.push(styles.template);
        location.pathname.includes('template') &&
            isEventListItem &&
            classNames.push(styles.eventTemplate);
        (isAdmin(user) || isEventOrganizer(user)) &&
            classNames.push(styles.adminView);
        return classNames.join(' ');
    }

    const hideActions =
        !(isAdmin(user) || isEventOrganizer(user)) &&
        (pastOrder || isPastDueDate);

    const isItemAddedLate: boolean =
        isEventListItem && !isEventTemplateItem && !item.eventTemplateItemId;

    return (
        <Tooltip
            title={isItemAddedLate ? t('item.late-item-add') : ''}
            placement="left"
        >
            <ListItem
                divider={true}
                sx={{
                    backgroundColor: isItemAddedLate ? 'info.light' : '',
                }}
            >
                <Box className={getListClassNames(styles.listItem)}>
                    <Box className={styles.itemImage}>
                        <ShoppingListItemImage item={item} />
                    </Box>
                    <Box className={styles.itemName}>
                        {item.url ? (
                            <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {(isAdmin(user) || isEventOrganizer(user)) && (
                                    <Tooltip
                                        title={`${t('item.item_creator')}: ${
                                            item.itemAdder.firstName
                                        } ${item.itemAdder.lastName}`}
                                        enterDelay={600}
                                        enterNextDelay={600}
                                    >
                                        <Typography variant="body1">
                                            {item.name ? item.name : item.url}
                                        </Typography>
                                    </Tooltip>
                                )}
                                {!(isAdmin(user) || isEventOrganizer(user)) && (
                                    <Typography variant="body1">
                                        {item.name ? item.name : item.url}
                                    </Typography>
                                )}
                            </a>
                        ) : (
                            <div>
                                {(isAdmin(user) || isEventOrganizer(user)) && (
                                    <Tooltip
                                        title={`${t('item.item_creator')}: ${
                                            item.itemAdder.firstName
                                        } ${item.itemAdder.lastName}`}
                                        enterDelay={600}
                                        enterNextDelay={600}
                                    >
                                        <Typography variant="body1">
                                            {item.name}
                                        </Typography>
                                    </Tooltip>
                                )}
                                {!(isAdmin(user) || isEventOrganizer(user)) && (
                                    <Typography variant="body1">
                                        {item.name}
                                    </Typography>
                                )}
                            </div>
                        )}
                    </Box>
                    <Box className={styles.itemShop}>
                        <Typography variant="body2" fontWeight="medium">
                            {item.shopName}
                        </Typography>
                    </Box>
                    <Box className={`flex-center ${styles.itemLikes}`}>
                        {!pastOrder && (
                            <Checkbox
                                icon={<ThumbUpOffAlt />}
                                checkedIcon={<ThumbUpAlt />}
                                checked={hasUserLikedItem(item, user)}
                                color="info"
                                onChange={handleItemLike}
                            ></Checkbox>
                        )}
                        {pastOrder && (
                            <Checkbox
                                icon={<ThumbUpOffAlt />}
                                checkedIcon={<ThumbUpAlt />}
                                checked={hasUserLikedItem(item, user)}
                                color="info"
                                disabled={true}
                            ></Checkbox>
                        )}
                        {getLikeAmount()}
                        {!pastOrder && (
                            <Checkbox
                                icon={<ThumbDownOffAlt />}
                                checkedIcon={<ThumbDownAlt />}
                                checked={hasUserDislikedItem(item, user)}
                                color="info"
                                onChange={handleItemDislike}
                            ></Checkbox>
                        )}
                        {pastOrder && (
                            <Checkbox
                                icon={<ThumbDownOffAlt />}
                                checkedIcon={<ThumbDownAlt />}
                                checked={hasUserDislikedItem(item, user)}
                                color="info"
                                disabled={true}
                            ></Checkbox>
                        )}
                        {getDislikeAmount()}
                    </Box>
                    {(isAdmin(user) || isEventOrganizer(user)) &&
                        !isEventTemplateItem && (
                            <Box
                                className={`flex-center ${styles.itemQuantity}`}
                            >
                                <TextField
                                    id="outlined-number"
                                    style={{ width: 75 }}
                                    type="number"
                                    value={amount}
                                    size="small"
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'center' },
                                    }}
                                    onChange={handleInputChange}
                                    onBlur={handleQuantityChange}
                                />
                            </Box>
                        )}

                    {(isAdmin(user) || isEventOrganizer(user)) &&
                        isEventListItem &&
                        location.pathname.includes('template') && (
                            <Box
                                className={`flex-center ${styles.itemPerPerson}`}
                            >
                                <TextField
                                    id="outlined-number"
                                    style={{ width: 85 }}
                                    type="number"
                                    value={perPerson}
                                    size="small"
                                    inputProps={{
                                        step: 0.1,
                                        min: 0,
                                        style: { textAlign: 'center' },
                                    }}
                                    onChange={handlePerPersonInputChange}
                                    onBlur={handlePerPersonAmountChange}
                                />
                            </Box>
                        )}

                    {(isAdmin(user) || isEventOrganizer(user)) &&
                        isEventListItem &&
                        location.pathname.includes('template') && (
                            <Box
                                className={`flex-center ${styles.itemAvg}`}
                                display={{ xs: 'none', sm: 'flex' }}
                            >
                                <Typography>{pastAverage}</Typography>
                            </Box>
                        )}

                    {!(isAdmin(user) || isEventOrganizer(user)) && pastOrder && (
                        <Box className={`flex-center ${styles.itemQuantity}`}>
                            <Typography
                                variant="body1"
                                color={
                                    item.amountOrdered === 0
                                        ? 'error.main'
                                        : 'inherit'
                                }
                            >
                                {item.amountOrdered}
                            </Typography>
                        </Box>
                    )}
                    {(isAdmin(user) || isEventOrganizer(user)) &&
                        !pastOrder &&
                        !location.pathname.includes('template') && (
                            <Box className={`flex-center ${styles.itemCheck}`}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedOutlinedIcon />}
                                    checkedIcon={<CheckCircleOutlineIcon />}
                                    checked={item.isChecked}
                                    color="info"
                                    onChange={handleItemCheck}
                                ></Checkbox>
                            </Box>
                        )}
                    {!hideActions && (
                        <Box className={`flex-center ${styles.itemActions}`}>
                            <ShoppingListItemActions item={item} />
                        </Box>
                    )}
                    {item.comment && (
                        <Box className={styles.itemComment}>
                            <ExpandableText
                                maxWords={8}
                                wordMaxLen={20}
                                text={item.comment}
                            ></ExpandableText>
                        </Box>
                    )}
                </Box>
            </ListItem>
        </Tooltip>
    );
};

export default ShoppingListItem;
