import { useState } from 'react';
import styles from './ShoppingListItemImage.module.css';
import { Box, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ItemDto } from '../../models/shopping-list/ItemDto';
import { itemBase64ToImage } from '../../utility/image-helper';

interface ShoppingListItemImageProps {
    item: ItemDto;
}

const ShoppingListItemImage = (
    props: ShoppingListItemImageProps
): JSX.Element => {
    const { item } = props;
    const [open, setOpen] = useState<boolean>(false);
    const handleClick = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <div>
            <Box
                className={styles.imageContainer}
                sx={{
                    '&:hover': {
                        opacity: [0.9, 0.8, 0.7],
                    },
                }}
            >
                <img
                    className={styles.imageSmall}
                    src={itemBase64ToImage(item)}
                    onClick={handleClick}
                />
            </Box>
            {open && (
                <Modal open={open} onClose={handleClose}>
                    <div className={styles.imageModal}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <img
                            className={styles.imageLarge}
                            src={itemBase64ToImage(item)}
                            alt={item.name}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default ShoppingListItemImage;
