import { ScraperConfigDto } from '../../models/shopping-list/ScraperConfigDto';
import ApiClient from '../client';
import { showResponseError } from '../ui/ui-slice';

export const listScrapeConfigs = async (): Promise<
    ScraperConfigDto[] | null
> => {
    const response = await ApiClient.get('search');
    if (!response.ok) {
        await showResponseError(response);
        return null;
    }
    return (await response.json()) as ScraperConfigDto[];
};

export const modifyScrapeConfig = async (
    data: ScraperConfigDto
): Promise<ScraperConfigDto | null> => {
    const response = await ApiClient.put(`search/${data.storeName}`, data);
    if (!response.ok) {
        await showResponseError(response);
        return null;
    }
    return (await response.json()) as ScraperConfigDto;
};

export const deleteScrapeConfig = async (
    name: string
): Promise<ScraperConfigDto | null> => {
    const response = await ApiClient.delete(`search/${name}`, {});
    if (!response.ok) {
        await showResponseError(response);
        return null;
    }
    return (await response.json()) as ScraperConfigDto;
};
