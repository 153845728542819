import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    listScrapeConfigs,
    modifyScrapeConfig,
    deleteScrapeConfig,
} from './scrape-config-actions';
import { ScraperConfigDto } from '../../models/shopping-list/ScraperConfigDto';
import { RESET_ALL, RootState } from '../store';

export interface ScrapeConfigState {
    scrapeConfigs: ScraperConfigDto[];
}

const initialState: ScrapeConfigState = {
    scrapeConfigs: [],
};

export const getScrapeConfigs = createAsyncThunk(
    'search',
    async (_, { rejectWithValue }) => {
        const response = await listScrapeConfigs();
        if (!response) {
            return rejectWithValue('Error fetching scrape configs.');
        }
        return response;
    }
);

export const updateScrapeConfig = createAsyncThunk(
    'search/modify',
    async (scrapeConfig: ScraperConfigDto, { rejectWithValue }) => {
        const response = await modifyScrapeConfig(scrapeConfig);
        if (!response) {
            return rejectWithValue('Error updating scrape config.');
        }
        return response;
    }
);

export const removeScrapeConfig = createAsyncThunk(
    'search/remove',
    async (scrapeConfigName: string, { rejectWithValue }) => {
        const response = await deleteScrapeConfig(scrapeConfigName);
        if (!response) {
            return rejectWithValue('Error deleting scrape config.');
        }
        return response;
    }
);

export const selectScrapeConfigByStoreName =
    (storeName: string) =>
    (state: RootState): ScraperConfigDto | undefined => {
        return state.scrapeConfigs.scrapeConfigs.find(
            (c) => c.storeName === storeName
        );
    };
export const selectScrapeConfigs = (
    state: RootState
): ScraperConfigDto[] | undefined => state.scrapeConfigs.scrapeConfigs;

export const scrapeConfigSlice = createSlice({
    name: 'scrape-config',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(RESET_ALL, () => initialState);
        builder.addCase(getScrapeConfigs.pending, (state) => {
            state.scrapeConfigs = [];
        });
        builder.addCase(getScrapeConfigs.fulfilled, (state, action) => {
            state.scrapeConfigs = action.payload;
        });
        builder.addCase(updateScrapeConfig.fulfilled, (state, action) => {
            state.scrapeConfigs = [
                ...state.scrapeConfigs.filter(
                    (store) => store.storeName !== action.payload.storeName
                ),
                action.payload,
            ];
        });
        builder.addCase(removeScrapeConfig.fulfilled, (state, action) => {
            state.scrapeConfigs = [
                ...state.scrapeConfigs.filter(
                    (store) => store.storeName !== action.payload.storeName
                ),
            ];
        });
    },
});

export default scrapeConfigSlice.reducer;
