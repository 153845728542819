import { useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { selectTemplates } from '../../store/shopping-list/shopping-list-slice';
import { Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import MainLayout from '../../components/main-layout/MainLayout';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { selectCurrentOffice } from '../../store/user/user-slice';
import OrderListItem from '../../components/order-list-item/OrderListItem';

const Templates = (): JSX.Element => {
    const { t } = useTranslation();
    const currentOffice = useAppSelector(selectCurrentOffice);
    const templates = useAppSelector(selectTemplates);
    const navigate = useNavigate();

    return (
        <MainLayout width="60em">
            <Grid2 container spacing={1} className="full-width">
                <Grid2 xs={12} className="flex-center">
                    <Typography
                        variant="h1"
                        display="flex"
                        justifyContent="center"
                    >
                        {t('list.templates')}
                    </Typography>
                </Grid2>
                <Grid2 xs={12} className="flex-center">
                    <Typography
                        variant="h3"
                        display="flex"
                        justifyContent="center"
                    >
                        {currentOffice?.name}
                    </Typography>
                </Grid2>
                <Grid2
                    xs={12}
                    className="flex-center"
                    sx={{ marginTop: '2rem' }}
                >
                    <Button
                        startIcon={<Add />}
                        variant="contained"
                        onClick={() => navigate('/templates/add')}
                    >
                        {t('actions.add_new_template')}
                    </Button>
                </Grid2>
                <Grid2 xs={12}>
                    {templates.map((list, i) => (
                        <OrderListItem list={list} key={i} />
                    ))}
                </Grid2>
                {templates.length === 0 && (
                    <Grid2 xs={12} className="flex-center">
                        <Typography
                            variant="body1"
                            align="center"
                            sx={{
                                marginTop: '15px',
                            }}
                        >
                            {t('list.no_templates')}
                            <br></br>
                            <SentimentDissatisfiedIcon
                                color="action"
                                fontSize="large"
                                sx={{
                                    marginTop: '15px',
                                }}
                            ></SentimentDissatisfiedIcon>
                        </Typography>
                    </Grid2>
                )}
            </Grid2>
        </MainLayout>
    );
};

export default Templates;
