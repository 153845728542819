import { useState, MouseEvent } from 'react';
import { Button, Menu, MenuItem, Typography, Checkbox } from '@mui/material';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ItemDto } from '../../models/shopping-list/ItemDto';
import Grid2 from '@mui/material/Unstable_Grid2';
import { MoreHoriz } from '@mui/icons-material';
import { SortOptions, SortType } from '../../utility/sort-helper';
import { getAllStores } from '../../utility/filter-helper';
import { useTranslation } from 'react-i18next';

interface SortMenuProps {
    sortTypes: SortType[];
    sortOptions: SortOptions;
    setSortOptions: (options: SortOptions) => void;
    columnName: String;
    filteredItems: ItemDto[];
    setFilterOptions: (options: String[]) => void;
}

const SortMenu = (props: SortMenuProps): JSX.Element => {
    const {
        sortTypes,
        sortOptions,
        setSortOptions,
        columnName,
        filteredItems,
        setFilterOptions,
    } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const filteredStores: String[] = getAllStores(filteredItems);

    const { t } = useTranslation();

    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleSortTypeClickedMenu = (
        event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>
    ): void => {
        const sortType = fromString(event.currentTarget.innerText);
        sortOptions.sortType = sortType;
        sortOptions.sortDescending = false;
        setSortOptions(sortOptions);
        setFilterOptions(filteredStores);
        setAnchorEl(null);
    };

    const fromString = function (s: string): SortType {
        if (s === t('list.likes')) {
            return SortType.Likes;
        } else if (s === t('list.popularity')) {
            return SortType.Popularity;
        }
        return SortType.Likes;
    };

    const toString = function (type: SortType): String {
        if (type === SortType.Likes) {
            return t('list.likes');
        } else if (type === SortType.Popularity) {
            return t('list.popularity');
        }
        return '';
    };

    return (
        <div>
            <Button
                sx={{
                    textTransform: 'none',
                    opacity: 1,
                    fontWeight: 'normal',
                    color: 'text.primary',
                    padding: '6px 0px',
                }}
                onClick={handleClick}
            >
                <Typography
                    variant="body1"
                    fontWeight="600"
                    color="text.primary"
                >
                    {columnName}
                </Typography>
                <Grid2 container spacing={0} minWidth={26} padding={0.5}>
                    <MoreHoriz color="disabled" />
                </Grid2>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    sx: {
                        '& .MuiMenuItem-root.Mui-selected': {
                            backgroundColor: 'transparent',
                        },
                        '& .MuiMenuItem-root:hover': {
                            backgroundColor: 'transparent',
                        },
                        '& .MuiMenuItem-root.Mui-selected:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                }}
            >
                <Typography
                    variant="body1"
                    fontWeight="normal"
                    color="text.primary"
                    alignSelf="stretch"
                    textAlign="center"
                >
                    {t('list.sort_by')}
                </Typography>
                {sortTypes.map((item, index) => (
                    <MenuItem key={index} onClick={handleSortTypeClickedMenu}>
                        <Checkbox
                            value={toString(item)}
                            icon={<RadioButtonUncheckedOutlinedIcon />}
                            checkedIcon={<CheckCircleOutlineIcon />}
                            checked={sortOptions.sortType === item}
                            disableRipple={true}
                            onChange={(e) => e.preventDefault()}
                        ></Checkbox>
                        <Typography
                            variant="body1"
                            fontWeight="normal"
                            color="text.primary"
                        >
                            {toString(item)}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default SortMenu;
