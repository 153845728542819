import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormLabel,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Favorite,
    FavoriteBorder,
    FileUpload,
    Delete,
    FindInPage,
    Search as SearchIcon,
} from '@mui/icons-material';
import {
    blobToDataUrl,
    compressDataUrl,
    dataUrlToBase64,
    getImageBase64Prefix,
    imgToDataUrl,
} from '../../utility/image-helper';
import { searchUrlForMetadata } from '../../store/shopping-list/metadata-url-scrape-actions';
import {
    getStoreSearchResults,
    selectStoreSearchResults,
} from '../../store/store-search/store-search-slice';
import { isUrl, oneFieldRequired } from '../../utility/validation-helper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useEffect, useState } from 'react';

import { AddItemDto } from '../../models/shopping-list/AddItemDto';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ItemDto } from '../../models/shopping-list/ItemDto';
import ItemFormImageUploader from '../item-form-image-uploader/ItemFormImageUploader';
import SearchResult from '../search-result/SearchResult';
import { SearchResultDto } from '../../models/shopping-list/SearchResultDto';
import styles from './ItemForm.module.css';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import {
    getScrapeConfigs,
    selectScrapeConfigs,
} from '../../store/settings/scrape-config-slice';

interface ItemFormProps {
    initialValues?: ItemDto;
    onSubmit?: SubmitHandler<AddItemDto>;
}

const ItemForm = (props: ItemFormProps): JSX.Element => {
    const { t } = useTranslation();
    const { initialValues } = props;

    const [selectedStore, setSelectedStore] = useState<string>('');
    const [searchString, setSearchString] = useState<string>('');
    const [selectedSearchResult, setSelectedSearchResult] =
        useState<SearchResultDto>();
    const [searchResultsFound, setSearchResultsFound] = useState<boolean>(true);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const allSearchResults = useAppSelector(selectStoreSearchResults);

    const scrapeConfigs = useAppSelector(selectScrapeConfigs);

    const fetchScrapeConfigs = async (): Promise<void> => {
        if (!scrapeConfigs || scrapeConfigs.length === 0) {
            await dispatch(getScrapeConfigs());
        }
    };

    useEffect(() => {
        void fetchScrapeConfigs();
    }, []);

    const searchItems = async (): Promise<void> => {
        setSelectedSearchResult(undefined);
        setIsSearching(true);
        setSearchResultsFound(true);
        await dispatch(
            getStoreSearchResults({
                storeName: selectedStore,
                searchTerm: searchString,
            })
        )
            .then(unwrapResult)
            .then((result: SearchResultDto[]) => {
                setIsSearching(false);
                if (result.length === 0) {
                    setSearchResultsFound(false);
                } else {
                    setSearchResultsFound(true);
                }
            })
            .catch(() => {
                setIsSearching(false);
                setSearchResultsFound(false);
            });
    };

    const onSearchResultClicked = (result: SearchResultDto): void => {
        setSelectedSearchResult(result);
        dispatch({ type: 'RESET_STORE_SEARCH' });
    };

    const changeSelectedStore = (store: string): void => {
        setSelectedStore(store);
        reset(defaultValues);
    };

    let defaultValues: Partial<AddItemDto> =
        initialValues === undefined
            ? {
                  name: '',
                  shopName: '',
                  url: '',
                  image: '',
                  comment: '',
                  like: true,
              }
            : {
                  name: initialValues.name,
                  shopName: initialValues.shopName,
                  url: initialValues.url,
                  image:
                      initialValues.image !== undefined &&
                      initialValues.image.length > 0
                          ? getImageBase64Prefix() + initialValues.image
                          : '',
                  comment: initialValues.comment,
              };

    const {
        control,
        handleSubmit,
        reset,
        trigger,
        watch,
        formState: { isValid, errors },
    } = useForm<AddItemDto>({
        defaultValues,
        mode: 'onChange',
    });

    useEffect(() => {
        if (selectedSearchResult) {
            reset({
                name: selectedSearchResult?.name,
                shopName: selectedStore,
                url: selectedSearchResult?.link,
                image: selectedSearchResult?.image,
                comment: '',
                like: true,
            });
        } else {
            reset({
                name: '',
                shopName: '',
                url: '',
                image: '',
                comment: '',
                like: true,
            });
        }
    }, [selectedSearchResult]);

    const watchNameAndUrl = watch(['name', 'url']);
    const watchImage = watch('image');
    const watchUrl = watch('url');

    useEffect(() => {
        reset(defaultValues);
        setImageVisible(defaultValues.image !== undefined);
        setImgUrl(defaultValues.image);
    }, [initialValues]);

    useEffect(() => {
        void trigger(['name', 'url']);
    }, [watchNameAndUrl[0], watchNameAndUrl[1]]);

    useEffect(() => {
        onImageUrlChanged(watchImage);
    }, [watchImage]);

    const onSubmit: SubmitHandler<AddItemDto> = async (data) => {
        if (formSubmitted) return;
        setFormSubmitted(true);

        if (isUploadedImage) {
            if (imageFile !== undefined) {
                try {
                    let imgDataUrl = await blobToDataUrl(imageFile);
                    // If you want to allow uncompressed images, comment out the below line
                    imgDataUrl = await compressDataUrl(imgDataUrl);
                    data.image = dataUrlToBase64(imgDataUrl);
                } catch (e) {
                    data.image = undefined;
                }
            }
        } else {
            if (imgUrl !== undefined) {
                try {
                    // If you want to allow uncompressed images, set below 2nd param to false
                    const imgDataUrl = await imgToDataUrl(imgUrl, true);
                    data.image = dataUrlToBase64(imgDataUrl);
                } catch (e) {
                    data.image = undefined;
                }
            }
        }
        props.onSubmit?.(data);
    };

    const [imgUrl, setImgUrl] = useState<string | undefined>();
    const [imageVisible, setImageVisible] = useState<boolean>(false);
    const [uploaderVisible, setUploaderVisible] = useState<boolean>(false);
    const [isUploadedImage, setImageIsUploaded] = useState<boolean>(false);
    const [imageFile, setImageFile] = useState<Blob | undefined>(undefined);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

    const onImageUrlChanged = (value: string | undefined): void => {
        setImageVisible(false);
        setImgUrl(value);
    };

    const onImageBlur = (): void => {
        if (imgUrl !== undefined && imgUrl.length > 0) {
            setImageVisible(true);
        }
    };

    const onUploadClicked = (): void => {
        setUploaderVisible(true);
    };

    const [metadataLoading, setMetadataLoading] = useState(false);

    const onMetadataUrlSearchClicked = async (): Promise<void> => {
        setMetadataLoading(true);
        if (watchUrl && selectedStore) {
            try {
                const result = await searchUrlForMetadata(
                    selectedStore,
                    watchUrl
                );
                defaultValues = {
                    ...defaultValues,
                    shopName: selectedStore,
                    name:
                        (result?.name as string) +
                        (result?.price ? ` (${result.price})` : ''),
                    url: watchUrl,
                    image: result?.image,
                };
                setMetadataLoading(false);
                reset(defaultValues);
            } catch {
                setMetadataLoading(false);
            }
        }
    };

    const onImageRemoveClicked = (): void => {
        setImageIsUploaded(false);
        setImgUrl(watchImage);
        if (watchImage && watchImage?.length > 0) {
            onImageBlur();
        } else {
            setImageVisible(false);
        }
    };

    return (
        <form
            id="Add-Item-Form"
            className={styles.addItem}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid2 container spacing={2}>
                <Grid2 xs={12}>
                    <Box className={styles.nameLabel}>
                        <FormLabel className={styles.label} id="shopName">
                            {t('item.shopName')}
                        </FormLabel>
                        {!initialValues && (
                            <Controller
                                name="like"
                                control={control}
                                render={({ field }) => (
                                    <Checkbox
                                        {...field}
                                        color="info"
                                        icon={<FavoriteBorder />}
                                        checkedIcon={<Favorite />}
                                        defaultChecked={true}
                                    />
                                )}
                            />
                        )}
                    </Box>
                    <Select
                        value={selectedStore}
                        label="Select"
                        fullWidth
                        onChange={(e) => changeSelectedStore(e.target.value)}
                    >
                        {scrapeConfigs?.map((scrapeConf) => (
                            <MenuItem
                                key={scrapeConf.storeName}
                                value={scrapeConf.storeName}
                            >
                                {scrapeConf.storeName}
                            </MenuItem>
                        ))}
                        <MenuItem value="other">{t('item.other')}</MenuItem>
                    </Select>
                </Grid2>
                {selectedStore !== 'other' && (
                    <Grid2 xs={12}>
                        <FormLabel className={styles.label} id="searchWord">
                            {t('item.search-item')}
                        </FormLabel>
                        <TextField
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            fullWidth
                            size="small"
                            variant="outlined"
                            onKeyPress={async (ev) => {
                                if (ev.key === 'Enter') {
                                    await searchItems();
                                    ev.preventDefault();
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            onClick={searchItems}
                                            aria-label="Search"
                                        >
                                            <SearchIcon />
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {isSearching && (
                            <Grid2>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            </Grid2>
                        )}
                        {!searchResultsFound && searchString.length > 0 && (
                            <Typography sx={{ color: 'red' }}>
                                {t('item.items-not-found')}
                            </Typography>
                        )}
                        {allSearchResults?.map(
                            (searchResult: SearchResultDto) => (
                                <SearchResult
                                    result={searchResult}
                                    onClickFunction={onSearchResultClicked}
                                    key={searchResult.link}
                                />
                            )
                        )}
                    </Grid2>
                )}
                {selectedStore === 'other' && (
                    <Grid2 xs={12}>
                        <FormLabel className={styles.label} id="shopName">
                            {t('item.store-name')}
                        </FormLabel>
                        <Controller
                            name="shopName"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    error={!!errors.shopName}
                                    aria-labelledby="shopName"
                                    helperText={errors.shopName?.message}
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid2>
                )}
                <Grid2 xs={12}>
                    <FormLabel className={styles.label} id="item_name">
                        {t('item.name')}
                    </FormLabel>
                    <Controller
                        name="name"
                        control={control}
                        rules={{
                            validate: {
                                validName: (value?: string) =>
                                    oneFieldRequired(
                                        value,
                                        watchNameAndUrl[1]
                                    ) || t('errors.one_value_required'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.name}
                                aria-labelledby="item_name"
                                helperText={errors.name?.message}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>
                <Grid2 xs={12}>
                    <FormLabel className={styles.label} id="url">
                        {t('item.url')}
                    </FormLabel>
                    <Box className={styles.nameLabel}>
                        <Controller
                            name="url"
                            control={control}
                            rules={{
                                validate: {
                                    validUrl: (value?: string) =>
                                        oneFieldRequired(
                                            value,
                                            watchNameAndUrl[0]
                                        ) || t('errors.one_value_required'),
                                    isUrl: (value?: string) =>
                                        !value ||
                                        isUrl(value) ||
                                        t('errors.url_not_valid'),
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="url"
                                    error={!!errors.url}
                                    aria-labelledby="url"
                                    helperText={errors.url?.message}
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                        <Tooltip title={t('actions.search_url_for_metadata')}>
                            <div>
                                <IconButton
                                    disabled={
                                        !watchUrl ||
                                        !selectedStore ||
                                        metadataLoading
                                    }
                                    onClick={onMetadataUrlSearchClicked}
                                >
                                    {metadataLoading ? (
                                        <CircularProgress size={26} />
                                    ) : (
                                        <FindInPage></FindInPage>
                                    )}
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Box>
                </Grid2>
                <Grid2 xs={12}>
                    <FormLabel className={styles.label} id="image">
                        {t('item.image')}
                    </FormLabel>
                    <Box className={styles.nameLabel}>
                        <Controller
                            name="image"
                            control={control}
                            defaultValue={t('item.image-url')}
                            render={({ field }) => (
                                <Tooltip
                                    title={
                                        isUploadedImage
                                            ? ''
                                            : t('item.image-url')
                                    }
                                >
                                    <TextField
                                        {...field}
                                        value={
                                            isUploadedImage
                                                ? t('item.image-url-upload')
                                                : field.value
                                        }
                                        onBlur={(event) => {
                                            field.onChange(event);
                                            onImageBlur();
                                        }}
                                        error={!!errors.image}
                                        aria-labelledby="image"
                                        size="small"
                                        helperText={errors.image?.message}
                                        disabled={isUploadedImage}
                                        fullWidth
                                    />
                                </Tooltip>
                            )}
                        />
                        <Tooltip title={t('actions.upload_image')}>
                            <IconButton onClick={onUploadClicked}>
                                <FileUpload></FileUpload>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {imageVisible && (
                        <img
                            className={styles.previewImage}
                            src={imgUrl}
                            alt={''}
                        />
                    )}

                    {isUploadedImage && (
                        <Tooltip title={t('actions.delete_image')}>
                            <IconButton onClick={onImageRemoveClicked}>
                                <Delete></Delete>
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid2>
                <Grid2 xs={12}>
                    <FormLabel className={styles.label} id="comment">
                        {t('item.comment')}
                    </FormLabel>
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.comment}
                                aria-labelledby="comment"
                                helperText={errors.comment?.message}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>
                <Grid2 xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!isValid || formSubmitted}
                        fullWidth
                    >
                        {initialValues
                            ? t('actions.save')
                            : t('actions.add_item')}
                    </Button>
                </Grid2>
            </Grid2>
            {uploaderVisible && (
                <ItemFormImageUploader
                    uploaderVisible={uploaderVisible}
                    setUploaderVisible={setUploaderVisible}
                    setImgUrl={setImgUrl}
                    setImageVisible={setImageVisible}
                    setImageIsUploaded={setImageIsUploaded}
                    setImageFile={setImageFile}
                ></ItemFormImageUploader>
            )}
        </form>
    );
};
export default ItemForm;
