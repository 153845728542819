import { ItemDto } from '../models/shopping-list/ItemDto';

const getCleanedStoreName = (item: ItemDto): String => {
    let name = item.shopName?.trim().toLocaleLowerCase();

    if (name === undefined) {
        name = '';
    } else {
        // Uppercase first letter, looks nicer on the filter menu
        name = name.charAt(0).toLocaleUpperCase() + name.slice(1);
    }
    return name;
};

export const getAllStores = (itemsList: ItemDto[]): String[] => {
    const stores: String[] = [];

    for (const item of itemsList) {
        const name = getCleanedStoreName(item);
        if (!stores.includes(name)) {
            stores.push(name);
        }
    }

    return stores;
};

export const filterByStoreNames = (
    itemsList: ItemDto[],
    stores: String[]
): ItemDto[] => {
    const filteredItems: ItemDto[] = [...itemsList].filter(
        (item) =>
            item.shopName !== undefined &&
            stores.includes(getCleanedStoreName(item))
    );
    return filteredItems;
};
