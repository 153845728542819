import ApiClient from '../client';
import { showErrorSnackbar } from '../ui/ui-slice';

export interface SearchResultDto {
    name: string;
    link: string;
    price?: string;
    image?: string;
}

export const searchUrlForMetadata = async (
    storeName: string,
    searchUrl: string
): Promise<SearchResultDto | null> => {
    const response = await ApiClient.get(
        `search/metadata/${storeName}/${encodeURIComponent(searchUrl)}`
    );
    if (!response.ok) {
        console.error(await response.text());
        await showErrorSnackbar('Error while looking up product data.');
        return null;
    }

    // Strip any extra html tags from the response that may be left over if scraper selectors are not configured perfectly.
    const responseObject = await response.json();
    for (const [key, value] of Object.entries(responseObject)) {
        responseObject[key] = (value as string).replace(/<[^>]*>?/gm, ''); // Replace HTML tags with empty
    }

    return responseObject as SearchResultDto;
};
