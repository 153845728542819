import {
    Button,
    FormLabel,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isUrl } from '../../utility/validation-helper';
import { ScraperConfigDto } from '../../models/shopping-list/ScraperConfigDto';
import styles from '../../features/app-settings/SettingsManagement.module.css';

interface ModifyStoreSearchFormProps {
    initialValues?: ScraperConfigDto | null;
    onSubmit: SubmitHandler<ScraperConfigDto>;
    addNew?: boolean;
}

const ModifyStoreSearchForm = (
    props: ModifyStoreSearchFormProps
): JSX.Element => {
    const { t } = useTranslation();

    const { initialValues, onSubmit, addNew } = props;

    const defaultValues: Partial<ScraperConfigDto> = initialValues ?? {
        storeName: '',
        searchUrl: '',
        searchResultSelector: '',
        productNameSelector: '',
        productLinkSelector: '',

        metadataUrlNameSelector: '',
        metadataUrlPriceSelector: '',
        metadataUrlImageSelector: '',
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ScraperConfigDto>({
        defaultValues,
        mode: 'onChange',
    });

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formContainer}
        >
            <Grid2 rowSpacing={0} xs={12}>
                {addNew && (
                    <Grid2>
                        <FormLabel className={styles.label} id="list-name">
                            {t('management.store_search.store_name')}
                        </FormLabel>
                        <Controller
                            name="storeName"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('errors.required'),
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    error={!!errors.storeName}
                                    aria-labelledby="first-name"
                                    helperText={t('errors.required')}
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid2>
                )}

                <Grid2
                    textAlign={'left'}
                    marginBottom={4}
                    marginTop={2}
                    xs={12}
                >
                    <Typography marginBottom={1} variant="h3">
                        {t('management.store_search.scraper_selector_header')}
                    </Typography>
                    {
                        t('management.store_search.scraper_selector_tip').split(
                            '<LINK>'
                        )[0]
                    }
                    <a
                        target="_blank"
                        href="https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector"
                        rel="noreferrer"
                    >
                        {t('management.store_search.query_selector_link')}
                    </a>
                    {
                        t('management.store_search.scraper_selector_tip').split(
                            '<LINK>'
                        )[1]
                    }
                </Grid2>

                <Grid2 marginBottom={4}>
                    <Tooltip
                        title={t('management.store_search.search_url_tip')}
                    >
                        <FormLabel className={styles.label} id="list-name">
                            {t('management.store_search.search_url')}
                            <InfoIcon />
                        </FormLabel>
                    </Tooltip>
                    <Controller
                        name="searchUrl"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('errors.required'),
                            },
                            validate: {
                                isUrl: (value?: string) =>
                                    !value ||
                                    isUrl(value) ||
                                    t('errors.url_not_valid'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.searchUrl}
                                aria-labelledby="first-name"
                                helperText={errors.searchUrl?.message}
                                placeholder={t(
                                    'management.store_search.search_url_example'
                                )}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>

                <Grid2>
                    <FormLabel className={styles.label} id="list-name">
                        {t('management.store_search.result_selector')}
                    </FormLabel>
                    <Controller
                        name="searchResultSelector"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('errors.required'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.searchResultSelector}
                                aria-labelledby="first-name"
                                helperText={t('errors.required')}
                                placeholder=".someClass > section > div"
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>

                <Grid2>
                    <FormLabel className={styles.label} id="list-name">
                        {t('management.store_search.product_link_selector')}
                    </FormLabel>
                    <Controller
                        name="productLinkSelector"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('errors.required'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.productLinkSelector}
                                aria-labelledby="first-name"
                                helperText={t('errors.required')}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>

                <Grid2>
                    <FormLabel className={styles.label} id="list-name">
                        {t('management.store_search.product_name_selector')}
                    </FormLabel>
                    <Controller
                        name="productNameSelector"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('errors.required'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.productNameSelector}
                                aria-labelledby="first-name"
                                helperText={t('errors.required')}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>

                <Grid2>
                    <FormLabel className={styles.label} id="list-name">
                        {t('management.store_search.price_selector')}
                    </FormLabel>
                    <Controller
                        name="productPriceSelector"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: t('errors.required'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.productPriceSelector}
                                aria-labelledby="first-name"
                                helperText={t('errors.required')}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>

                <Grid2>
                    <FormLabel className={styles.label} id="list-name">
                        {t('management.store_search.image_selector')}
                    </FormLabel>
                    <Controller
                        name="productImageSelector"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: t('errors.required'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.productImageSelector}
                                aria-labelledby="first-name"
                                helperText={t('errors.required')}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>

                <Grid2
                    textAlign={'left'}
                    marginBottom={4}
                    marginTop={2}
                    xs={12}
                >
                    <Typography marginBottom={1} variant="h3">
                        {t(
                            'management.store_search.single_url_selector_header'
                        )}
                    </Typography>
                    {t('management.store_search.single_url_selector_tip')}
                </Grid2>

                <Grid2>
                    <FormLabel className={styles.label} id="list-name">
                        {t('management.store_search.url_product_name_selector')}
                    </FormLabel>
                    <Controller
                        name="metadataUrlNameSelector"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('errors.required'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.metadataUrlNameSelector}
                                aria-labelledby="first-name"
                                helperText={t('errors.required')}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>

                <Grid2>
                    <FormLabel className={styles.label} id="list-name">
                        {t('management.store_search.url_price_selector')}
                    </FormLabel>
                    <Controller
                        name="metadataUrlPriceSelector"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: t('errors.required'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.metadataUrlPriceSelector}
                                aria-labelledby="first-name"
                                helperText={t('errors.required')}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>

                <Grid2>
                    <FormLabel className={styles.label} id="list-name">
                        {t('management.store_search.url_image_selector')}
                    </FormLabel>
                    <Controller
                        name="metadataUrlImageSelector"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: t('errors.required'),
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.metadataUrlImageSelector}
                                aria-labelledby="first-name"
                                helperText={t('errors.required')}
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid2>

                <Grid2
                    container
                    justifyContent={'center'}
                    marginTop={4}
                    xs={12}
                >
                    <Button type="submit" variant="contained">
                        {t('actions.save')}
                    </Button>
                </Grid2>
            </Grid2>
        </form>
    );
};

export default ModifyStoreSearchForm;
