import styles from './ItemFormImageUploader.module.css';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal,
    Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState, DragEvent, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ItemFormImageUploaderProps {
    uploaderVisible: boolean;
    setUploaderVisible: (value: boolean) => void;
    setImgUrl: (value: string | undefined) => void;
    setImageVisible: (value: boolean) => void;
    setImageIsUploaded: (value: boolean) => void;
    setImageFile: (value: Blob) => void;
}

const ItemFormImageUploader = (
    props: ItemFormImageUploaderProps
): JSX.Element => {
    const {
        uploaderVisible,
        setUploaderVisible,
        setImgUrl,
        setImageVisible,
        setImageIsUploaded,
        setImageFile,
    } = props;

    const { t } = useTranslation();

    const [dragActive, setDragActive] = useState<boolean>(false);
    const [invalidFile, setIsInvalidFile] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleClose = (): void => {
        setUploaderVisible(false);
    };

    const handleDialogClose = (): void => {
        setIsInvalidFile(false);
    };

    const handleDrag = (
        event: DragEvent<HTMLFormElement> | DragEvent<HTMLDivElement>
    ): void => {
        event.preventDefault();
        event.stopPropagation();

        if (event.type === 'dragenter' || event.type === 'dragover') {
            setDragActive(true);
        } else if (event.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);

        if (event.dataTransfer.files?.[0] !== undefined) {
            onFileSelected(event.dataTransfer.files[0]);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        if (event.target.files?.[0] !== undefined) {
            onFileSelected(event.target.files[0]);
        }
    };

    const onFileSelected = (file: File): void => {
        // Only support image files
        if (file.type.startsWith('image/')) {
            setImageFile(file);
            setImgUrl(URL.createObjectURL(file));
            handleClose();
            setImageVisible(true);
            setImageIsUploaded(true);
        } else {
            setIsInvalidFile(true);
        }
    };

    const onButtonClick = (): void => {
        inputRef.current?.click();
    };

    function getClassNames(): string {
        const classNames: string[] = [];
        classNames.push(styles.labelFileUpload);
        if (dragActive) {
            classNames.push(styles.dragActive);
        }
        return classNames.join(' ');
    }

    return (
        <Modal open={uploaderVisible} onClose={handleClose}>
            <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
            >
                <div className={styles.imageModal}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle
                        id="dialog-title"
                        sx={{ textAlign: 'center', color: 'black' }}
                    >
                        {t('actions.upload_product_image')}
                    </DialogTitle>
                    <input
                        id="input-file-upload"
                        className={styles.inputFileUpload}
                        type="file"
                        accept="image/*"
                        multiple={false}
                        onChange={handleChange}
                        ref={inputRef}
                    />
                    <label
                        id="label-file-upload"
                        htmlFor="input-file-upload"
                        className={getClassNames()}
                    >
                        <div className={styles.centered}>
                            <Typography className={styles.infoText}>
                                {t('actions.upload_image_instructions')}
                            </Typography>
                            <Button
                                type="button"
                                className="upload-button"
                                onClick={onButtonClick}
                            >
                                {t('actions.upload_image')}
                            </Button>
                        </div>
                    </label>
                    {dragActive && (
                        <div
                            className={styles.dragFileElement}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                        ></div>
                    )}
                    {invalidFile && (
                        <Dialog
                            open={invalidFile}
                            onClose={handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle>
                                {t('errors.upload_image_invalid_header')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t('errors.upload_image_invalid')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDialogClose} autoFocus>
                                    {t('actions.ok')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </div>
            </form>
        </Modal>
    );
};

export default ItemFormImageUploader;
