import { Edit, Delete } from '@mui/icons-material';
import {
    DialogContentText,
    IconButton,
    ListItem,
    Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScraperConfigDto } from '../../models/shopping-list/ScraperConfigDto';
import ConfirmationDialog from '../confirmation-popup/ConfirmationDialog';

interface ScrapeConfigItemProps {
    config: ScraperConfigDto;
    handleEdit: () => void;
    handleDelete: (toDelete: ScraperConfigDto) => void;
}

const ScrapeConfigItem = (props: ScrapeConfigItemProps): JSX.Element => {
    const { t } = useTranslation();
    const { config, handleEdit, handleDelete } = props;
    const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);

    const onDelete = (item: ScraperConfigDto): void => {
        setDeleteOpen(false);
        handleDelete(item);
    };

    return (
        <ListItem divider={true}>
            <Grid2
                container
                xs={12}
                wrap="nowrap"
                justifyContent="space_between"
                alignItems="center"
            >
                <Grid2 container xs={7} justifyContent="flex-start">
                    <Typography variant="h3" fontWeight="medium">
                        {config.storeName}
                    </Typography>
                </Grid2>
                <Grid2
                    container
                    xs={5}
                    columnSpacing={4}
                    justifyContent="flex-end"
                >
                    <IconButton
                        aria-label="delete"
                        onClick={() => setDeleteOpen(true)}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton aria-label="rename" onClick={handleEdit}>
                        <Edit />
                    </IconButton>
                </Grid2>
            </Grid2>
            <ConfirmationDialog
                open={isDeleteOpen}
                onConfirm={() => onDelete(config)}
                onCancel={() => setDeleteOpen(false)}
                title={t('actions.remove_store_search')}
            >
                <DialogContentText>
                    {t('dialogs.confirmation')}
                </DialogContentText>
            </ConfirmationDialog>
        </ListItem>
    );
};

export default ScrapeConfigItem;
