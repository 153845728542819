import { RESET_ALL, RootState } from '../store';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SearchResultDto } from '../../models/shopping-list/SearchResultDto';
import { StoreSearchPayload } from './store-search-types';
import { searchItemFromStore } from './store-search-actions';

export interface StoreSearchResultsState {
    storeSearchResults: SearchResultDto[];
}

const initialState: StoreSearchResultsState = {
    storeSearchResults: [],
};

export const getStoreSearchResults = createAsyncThunk(
    'search/store/item',
    async (data: StoreSearchPayload, { rejectWithValue }) => {
        const response = await searchItemFromStore(
            data.storeName,
            data.searchTerm
        );
        if (!response) {
            return rejectWithValue(
                'An error occured searching item from a store.'
            );
        }

        for (const searchResult of response) {
            // Strip any extra html tags from the response that may be left over if scraper selectors are not configured perfectly.
            searchResult.price = searchResult.price.replace(/<[^>]*>?/gm, '');

            // Make sure that there is always € mark in the end of the price.
            searchResult.price = searchResult.price.replace('€', '');
            searchResult.price = searchResult.price + ' €';
        }

        return response;
    }
);

export const RESET_STORE_SEARCH = createAction('RESET_STORE_SEARCH');

export const selectStoreSearchResults = (
    state: RootState
): SearchResultDto[] | undefined => state.storeSearch.storeSearchResults;

export const storeSearchResultsSlice = createSlice({
    name: 'store-search-results',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(RESET_ALL, () => initialState);
        builder.addCase(getStoreSearchResults.pending, (state) => {
            state.storeSearchResults = [];
        });
        builder.addCase(getStoreSearchResults.fulfilled, (state, action) => {
            // Takes only first 5 search results to show to the user
            state.storeSearchResults = action.payload.slice(0, 5);
        });
        builder.addCase(RESET_STORE_SEARCH, () => initialState);
    },
});

export default storeSearchResultsSlice.reducer;
