import { ItemDto } from '../models/shopping-list/ItemDto';
import { UserDto } from '../models/user/UserDto';
import { isAdmin } from './user-helper';

export enum SortType {
    Name,
    Likes,
    Store,
    Popularity,
    None,
}

export interface SortOptions {
    sortType: SortType;
    sortDescending: boolean;
}

const compareNames = (a: ItemDto, b: ItemDto): number => {
    if (a.name === undefined || b.name === undefined) return 0;
    else return a.name.localeCompare(b.name);
};

export const sortByItemName = (
    itemsList: ItemDto[],
    descending: boolean
): ItemDto[] => {
    const sortedItems: ItemDto[] = [...itemsList].sort(compareNames);
    if (descending) {
        return sortedItems;
    } else return sortedItems.reverse();
};

export const sortByStoreName = (
    itemsList: ItemDto[],
    descending: boolean
): ItemDto[] => {
    const sortedItems: ItemDto[] = [...itemsList].sort(compareStores);
    if (descending) {
        return sortedItems;
    } else return sortedItems.reverse();
};

export const sortByItemLikes = (
    itemsList: ItemDto[],
    descending: boolean
): ItemDto[] => {
    const sortedItems: ItemDto[] = [...itemsList].sort((a, b) => {
        if (a.usersWhoLiked.length !== b.usersWhoLiked.length) {
            return a.usersWhoLiked.length - b.usersWhoLiked.length;
        } else {
            return b.usersWhoDisliked.length - a.usersWhoDisliked.length;
        }
    });
    if (descending) {
        return sortedItems;
    } else return sortedItems.reverse();
};

export const wilson = function (item: ItemDto): number {
    const CC = 1.96;
    const total = item.usersWhoLiked.length + item.usersWhoDisliked.length;

    if (total === 0) {
        return 0;
    }

    const posFrac = item.usersWhoLiked.length / total;

    return (
        (posFrac +
            (CC * CC) / (2 * total) -
            CC *
                Math.sqrt(
                    (posFrac * (1 - posFrac) + (CC * CC) / (4 * total)) / total
                )) /
        (1 + (CC * CC) / total)
    );
};

export const sortByPopularity = (
    itemsList: ItemDto[],
    descending: boolean
): ItemDto[] => {
    const sortedItems: ItemDto[] = [...itemsList].sort((a, b) => {
        if (wilson(a) !== wilson(b)) {
            return wilson(a) - wilson(b);
        } else {
            return b.usersWhoDisliked.length - a.usersWhoDisliked.length;
        }
    });
    if (descending) {
        return sortedItems;
    } else return sortedItems.reverse();
};

const compareRoles = (a: UserDto, b: UserDto): number => {
    const isAAdmin = isAdmin(a);
    const isBAdmin = isAdmin(b);
    if (isAAdmin && !isBAdmin) return -1;
    if (!isAAdmin && isBAdmin) return 1;
    return 0;
};

const compareStores = (a: ItemDto, b: ItemDto): number => {
    if (a.shopName === undefined || b.shopName === undefined) return 0;
    return a.shopName.localeCompare(b.shopName);
};

const compareRolesAndName = (a: UserDto, b: UserDto): number => {
    const sortByRole = compareRoles(a, b);
    if (sortByRole !== 0) return sortByRole;
    const sortByLastName = a.lastName.localeCompare(b.lastName);
    return sortByLastName !== 0
        ? sortByLastName
        : a.firstName.localeCompare(b.firstName);
};

export const sortByUserRoleAndName = (userList: UserDto[]): UserDto[] => {
    return [...userList].sort(compareRolesAndName);
};
