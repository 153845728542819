import ApiClient from '../client';
import { SearchResultDto } from '../../models/shopping-list/SearchResultDto';
import { showResponseError } from '../ui/ui-slice';

export const searchItemFromStore = async (
    storeName: string,
    searchTerm: string
): Promise<SearchResultDto[] | null> => {
    const response = await ApiClient.get(`Search/${storeName}/${searchTerm}`);
    if (!response.ok) {
        await showResponseError(response);
        return null;
    }
    return (await response.json()) as SearchResultDto[];
};
