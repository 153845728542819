import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { selectCurrentUser } from '../../store/user/user-slice';
import DropdownMenu, { DropdownMenuItem } from '../dropdown-menu/DropdownMenu';
import { showSuccessSnackBar } from '../../store/ui/ui-slice';
import { isAdmin, isEventOrganizer } from '../../utility/user-helper';
import {
    addShoppingListAsync,
    selectShoppingListById,
} from '../../store/shopping-list/shopping-list-slice';
import { unwrapResult } from '@reduxjs/toolkit';
import { AddShoppingListDto } from '../../models/shopping-list/AddShoppingListDto';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface CopyListMenuProps {
    listId: number;
}

const CopyListMenu = (props: CopyListMenuProps): JSX.Element | null => {
    const user = useAppSelector(selectCurrentUser);
    const shoppingList = useAppSelector(selectShoppingListById(props.listId));
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [items, setItems] = useState<DropdownMenuItem[]>([]);

    const copyShoppingListLink = async (): Promise<void> => {
        const listUrl = `${window.location.origin}/order/${props.listId}`;
        await navigator.clipboard.writeText(listUrl);
        await showSuccessSnackBar(t('list.list-copy-successful'));
    };

    const copyShoppingListTemplate = async (): Promise<void> => {
        if (shoppingList) {
            const addTemplate: AddShoppingListDto = {
                name: shoppingList.name,
                comment: shoppingList.comment,
                officeId: shoppingList.listDeliveryOffice.id,
                items: shoppingList.items,
                template: true,
                event: shoppingList.event,
            };
            unwrapResult(await dispatch(addShoppingListAsync(addTemplate)));
            await showSuccessSnackBar(t('list.list-copy-template-successful'));
        }
    };

    useEffect(() => {
        let items = [];
        if (isAdmin(user) || isEventOrganizer(user)) {
            if (location.pathname.includes('template')) {
                items = [
                    {
                        title: t('list.list-copy-template-link'),
                        onClick: copyShoppingListLink,
                    },
                    {
                        title: t('actions.clone_template'),
                        onClick: copyShoppingListTemplate,
                    },
                ];
            } else {
                items = [
                    {
                        title: t('list.list-copy-link'),
                        onClick: copyShoppingListLink,
                    },
                    {
                        title: t('list.list-copy-template'),
                        onClick: copyShoppingListTemplate,
                    },
                ];
            }
        } else {
            items = [
                {
                    title: t('list.list-copy-link'),
                    onClick: copyShoppingListLink,
                },
            ];
        }
        setItems(items);
    }, []);

    return (
        <>
            <DropdownMenu title={''} items={items} isDots={true} />
        </>
    );
};

export default CopyListMenu;
